import { gql, useMutation } from '@apollo/client';

import { processErrors } from '../AccountControls';
import {
  CreateLinkedCochraneReviewMutation,
  CreateLinkedCochraneReviewInput,
} from 'types/graphql';

const processCochraneResult = (
  result?: CreateLinkedCochraneReviewMutation | null
) => {
  const newReviewId = result?.createLinkedCochraneReview?.review?.id;
  const errors = result?.createLinkedCochraneReview?.errors;
  const success = result?.createLinkedCochraneReview?.success;
  return { newReviewId, errors, success };
};

const CREATE_LINKED_COCHRANE_REVIEW_MUTATION = gql`
  mutation CreateLinkedCochraneReview(
    $accountId: ID!
    $cochraneReviewId: ID!
    $enableRctClassifier: Boolean
    $enableRctAutoExclusion: Boolean
    $enablePicsAutoExclusion: Boolean
  ) {
    createLinkedCochraneReview(
      input: {
        accountId: $accountId
        cochraneReviewId: $cochraneReviewId
        enableRctClassifier: $enableRctClassifier
        enableRctAutoExclusion: $enableRctAutoExclusion
        enablePicsAutoExclusion: $enablePicsAutoExclusion
      }
    ) {
      success
      errors {
        message
      }

      review {
        id
        title
      }
    }
  }
`;

export const useLinkReviewMutation = () => {
  const [
    createLinkedReview,
    { loading: submitting, data: result, error: submitError },
  ] = useMutation<
    CreateLinkedCochraneReviewMutation,
    CreateLinkedCochraneReviewInput
  >(CREATE_LINKED_COCHRANE_REVIEW_MUTATION);

  const { success, newReviewId } = processCochraneResult(result);

  const errors = processErrors({
    apolloError: submitError,
    createReviewResult: result,
    submitting,
    errors:
      result?.createLinkedCochraneReview?.errors?.map((e) => {
        return { message: e.message };
      }) || [],
    success: !!success,
  });

  return {
    createLinkedReview,
    success,
    newReviewId,
    submitting,
    errors,
  };
};
