import { pick } from 'ramda';
import {
  CheckboxesBlock,
  CheckboxesBlockValue,
} from 'concepts/Extraction/Blocks/Checkboxes/CheckboxesBlock';
import { BlockRecord } from 'concepts/Extraction/BlocksData';

/**
 * Creates CheckboxesBlock['options'] with all optionIds set to false
 */
const getUnselectedOptions = (
  options: CheckboxesBlock['options']
): CheckboxesBlockValue['options'] =>
  options.reduce(
    (accumulator, { id }) => ({ ...accumulator, [id]: false }),
    {}
  );

export const getInitialCheckboxesBlockValue = (
  options: CheckboxesBlock['options'],
  customValue?: Partial<CheckboxesBlockValue>
): CheckboxesBlockValue => ({
  ...customValue,
  options: {
    ...getUnselectedOptions(options),
    ...customValue?.options,
  },
  otherOption: {
    value: null,
    selected: false,
    ...customValue?.otherOption,
  },
});

export const toggleCheckboxOption = (
  value: CheckboxesBlockValue,
  optionId: string
): CheckboxesBlockValue => ({
  ...value,
  options: {
    ...value.options,
    [optionId]: !value.options[optionId],
  },
});

export const toggleCheckboxOtherOption = (
  value: CheckboxesBlockValue
): CheckboxesBlockValue => ({
  ...value,
  otherOption: {
    ...value.otherOption,
    selected: !value.otherOption.selected,
    /**
     * @remarks resets value as well if unselected to replicate multiple choice behaviour
     * I think a better behaviour is to replicate Google Forms:
     * - checkbox should not be disabled at any time
     * - typing in text field automatically checks checkbox
     * - having empty field automatically un-checks checkbox
     * - preserve the text value regardless of whether checkbox is checked
     */
    value: !value.otherOption.selected === true ? value.otherOption.value : '',
  },
});

export const updateCheckboxOtherOptionValue = (
  value: CheckboxesBlockValue,
  otherOptionValue: CheckboxesBlockValue['otherOption']['value']
): CheckboxesBlockValue => ({
  ...value,
  otherOption: {
    ...value.otherOption,
    value: otherOptionValue,
  },
});

type GetSupersedingCheckboxesBlockValueProps = {
  precedingValue: BlockRecord<CheckboxesBlockValue>['value'];
  supersedingBlock: CheckboxesBlock;
};

/**
 * When a Checkboxes block is modified in DE template, preserve applicable
 * option select states and discard values for removed options.
 *
 * This is a new pattern attempts to retain applicable any values compared to the all-or-nothing
 * approach of other extraction blocks using  keepResponse() boolean used in
 * concepts/Extraction/Blocks/migrate.ts to decide to retain block values or discard them entirely.
 */
export const getSupersedingCheckboxesBlockValue = ({
  precedingValue,
  supersedingBlock,
}: GetSupersedingCheckboxesBlockValueProps): CheckboxesBlockValue => {
  const supersedingOptionIds = supersedingBlock.options.map(({ id }) => id);

  // Values for options with ids that still exist in the superseding block are kept.
  const migratableOptionValues: CheckboxesBlockValue['options'] = precedingValue
    ? pick(supersedingOptionIds, precedingValue.options)
    : {};

  const isOtherOptionEnabledInSupersedingBlock =
    supersedingBlock.otherOption !== null;

  const supersedingValue = getInitialCheckboxesBlockValue(
    supersedingBlock.options,
    {
      options: migratableOptionValues,
      // Transfer other option selected state & value if other option is still enabled
      ...(isOtherOptionEnabledInSupersedingBlock && {
        otherOption: precedingValue?.otherOption,
      }),
    }
  );

  return supersedingValue;
};
