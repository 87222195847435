import React, { useState } from 'react';

import { formatDateString } from '../util';
import styles from '../CitationImportHistory.module.scss';
import StatBox from './StatBox';
import UndoControl from './UndoControl';
import { CitationSourcesForm } from './CitationSourcesForm';
import { getReviewId, undoCitationImport } from 'query/review';
import { tokenizeRoute, DUPLICATES_ROUTE } from 'query/routes';
import { CitationImport as CitationType } from 'types';
import { createToast, Toast, ToastContainer } from 'components/core';
import {
  Icon,
  IconList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from 'components/core';

const visibleCategory = (category: string) => {
  if (category === 'Screen') return 'screening';
  if (category === 'Select') return 'full text review';
  return category.toLowerCase();
};

interface Props extends CitationType {
  reviewImportSources: string[];
  setReviewImportSources: (sourceNames: string[]) => void;
  customReviewImportSources: { id: string; sourceName: string }[];
  setCustomReviewImportSources: any;
}

export const CitationImport = ({
  id,
  state,
  filename,
  category,
  createdAt,
  counts,
  canUndo,
  undoDate,
  importSourcesPlaceholder,
  citationImportSources,
  reviewImportSources,
}: Props) => {
  const [_undoDate, setUndoDate] = useState(undoDate);
  const [importState, setImportState] = useState(state);
  const [sourcesPopoverIsOpen, setSourcesPopoverIsOpen] = useState(false);
  const [
    storedCitationImportSources,
    setStoredCitationImportSources,
  ] = useState(citationImportSources);
  const [
    storedImportSourcesPlaceholderText,
    setStoredImportSourcesPlaceholderText,
  ] = useState(importSourcesPlaceholder);

  let loading = false;
  const onUndo = () => {
    if (!loading) {
      const oldState = importState;
      setImportState('undoing');
      undoCitationImport(id)
        .then((res) => {
          loading = false;
          if (res.ok) {
            setUndoDate(new Date().toString());
            setImportState('undone');
          } else {
            return Promise.reject();
          }
        })
        .catch(() => {
          setImportState(oldState);
          createToast(
            <Toast type="danger" role="alert">
              {'Unable to undo import as this time.'}
            </Toast>
          );
        });
    }
    loading = true;
  };

  return (
    <section data-citation-import-id={id}>
      <ToastContainer />
      <div className={styles.CitationImport_header}>
        <div className={styles.CitationImport_header_info}>
          <Text component="h3" size="sm">
            {filename || `File import to ${category}`}
          </Text>
          <Text variant="weak">{formatDateString(createdAt)}</Text>
        </div>
        <div className={styles.CitationImport_header_actions}>
          <UndoControl
            state={importState}
            canUndo={canUndo}
            undoDate={_undoDate}
            onClick={onUndo}
          />
        </div>
      </div>
      <ul className={styles.CitationImport_stats}>
        <li>
          <StatBox
            header={`Added to ${visibleCategory(category)}`}
            value={<Text size="xxl">{counts.imported.toString()}</Text>}
            solid
          />
        </li>
        <li>
          <StatBox
            header="References"
            value={<Text size="xxl">{counts.citations.toString()}</Text>}
          />
        </li>
        <li>
          <StatBox
            header={
              <span>
                Duplicates <Icon icon={IconList.light.faExternalLink} />
              </span>
            }
            parentClick={() => {
              window.location.href = tokenizeRoute(DUPLICATES_ROUTE, {
                review_id: getReviewId(),
                import_id: id,
              }).toString();
            }}
            value={<Text size="xxl">{counts.duplicates.toString()}</Text>}
            clickable
          />
        </li>
        <li>
          <StatBox
            header={
              <Tooltip hasArrow>
                <TooltipTrigger>
                  <Text component="span">
                    Merged <Icon icon={IconList.light.faCircleQuestion} />
                  </Text>
                </TooltipTrigger>
                <TooltipContent>
                  The number of studies that have been merged since this import
                </TooltipContent>
              </Tooltip>
            }
            value={<Text size="xxl">{counts.merged.toString()}</Text>}
          />
        </li>
        <li>
          <Popover
            isOpen={sourcesPopoverIsOpen}
            renderCloseButton={false}
            placement="bottom-end"
          >
            <PopoverTrigger>
              <StatBox
                header={
                  <span>
                    <Text
                      component="span"
                      data-testid={`edit-citation-sources-${id}`}
                    >
                      Source <Icon icon={IconList.light.faPenToSquare} />
                    </Text>
                  </span>
                }
                value={
                  <Text size="md">{storedImportSourcesPlaceholderText}</Text>
                }
                clickable
                parentClick={() =>
                  setSourcesPopoverIsOpen(!sourcesPopoverIsOpen)
                }
              />
            </PopoverTrigger>

            <PopoverContent
              className={styles.CitationImportSourcesPopover_content}
            >
              <CitationSourcesForm
                citationImportSources={storedCitationImportSources}
                setStoredCitationImportSources={setStoredCitationImportSources}
                setStoredImportSourcesPlaceholderText={
                  setStoredImportSourcesPlaceholderText
                }
                reviewImportSources={reviewImportSources}
                citationImportId={id}
                setSourcesPopoverIsOpen={setSourcesPopoverIsOpen}
                citationImportTotalCitations={counts.citations}
              />
            </PopoverContent>
          </Popover>
        </li>
      </ul>
      {counts.errors ? (
        <Text className={styles.CitationImport_error}>
          <Icon
            icon={IconList.solid.faCircleExclamation}
            className={styles.CitationImport_error_icon}
          />
          {counts.errors} references failed to import due to errors
        </Text>
      ) : (
        ''
      )}
    </section>
  );
};

export default CitationImport;
