import React, { useState } from 'react';
import Select from 'react-select';
import Props, { BillableAccount } from '../reviewInnerProps';
import { NewAutomationOptions } from '../AutomationOptions';

import { AccountControls } from '../AccountControls';
import CantLinkCochrane from '../CantLinkCochrane/CantLinkCochrane';
import { useLinkReviewMutation } from './useCreateNewCochraneReview';
import { useCochraneReviewsQuery } from './useCochraneReviewsQuery';
import cochraneLogo from 'assets/images/cochrane-brandmark.svg';
import ErrorMessage from 'components/ErrorMessage';
import { Maybe, CochraneReview } from 'types/graphql';

const NewCochraneLinkedReview = ({
  account,
  picsAutoExclusionUi,
  setSelectedAccountId,
  accounts,
  isCochraneUser,
}: Props) => {
  const [selectedReview, selectReview] = useState<CochraneReview | null>(null);
  const [enableRctClassifier, setEnableRctClassifier] = useState(false);
  const [enableRctAutoExclusion, setEnableRctAutoExclusion] = useState(false);
  const [enablePicsAutoExclusion, setEnablePicsAutoExclusion] = useState(false);
  const {
    createLinkedReview,
    submitting,
    newReviewId,
    success,
    errors,
  } = useLinkReviewMutation();

  const { collection, loading, error } = useCochraneReviewsQuery();

  let reviewSelector;

  if (error) {
    reviewSelector = <ErrorMessage>{error.message}</ErrorMessage>;
  }

  const isSubmittable = selectedReview !== null && !submitting;

  const submit = () => {
    /**
     * Using the following instead of `if (!isSubmittable) return;`
     * because the latter oddly throws a "selectedReview is possibly null" type-
     * check error when accessing `selectedReview.id`
     *
     * We could alternatively just assert selectedReview is not null (we should
     * try to avoid type assertions), or use a isSelectedReviewNull type guard
     * function (less readable, results in redundant transpiled JS condition
     * checks).
     */
    if (selectedReview === null || submitting) return;

    createLinkedReview({
      variables: {
        accountId: account.id,
        cochraneReviewId: selectedReview.id,
        enableRctClassifier,
        enableRctAutoExclusion,
        enablePicsAutoExclusion,
      },
    });
  };

  reviewSelector = (
    <>
      <Select<CochraneReview>
        inputId="review"
        classNamePrefix="review"
        options={collection as any}
        isLoading={loading}
        openMenuOnFocus={true}
        isSearchable={true}
        getOptionLabel={(review) =>
          review.link ? `${review.title} (already linked)` : review.title
        }
        onChange={(review: any) =>
          selectReview(review as Maybe<CochraneReview>)
        }
        isOptionDisabled={(review: CochraneReview) => review.link !== null}
        isOptionSelected={(review: CochraneReview) => review === selectedReview}
        styles={{
          // Make room for Cochrane logo
          control: (s) => ({ ...s, paddingLeft: '25px' }),
        }}
      />
      <img
        src={cochraneLogo}
        alt="Cochrane"
        className="new_cochrane_review--cochrane-logo pull-left"
      />
    </>
  );

  if (success && newReviewId) {
    window.location.href = `/reviews/${newReviewId}/authors`;
    return null;
  }

  if (!isCochraneUser) {
    return (
      <CantLinkCochrane
        picsAutoExclusionUi={picsAutoExclusionUi}
        account={account}
        accounts={accounts}
        hasTrialed={account.hasTrialed}
        setSelectedAccountId={setSelectedAccountId}
      />
    );
  }

  return (
    <>
      <div className="row">
        <div className="form-group col-md-8">
          <label htmlFor="review" className="new_cochrane_review--title">
            Choose a Cochrane review
            <p className="help-block">
              Choose the name of your review as it appears in Archie
            </p>
            {reviewSelector}
          </label>
          <NewAutomationOptions
            picsAutoExclusionUi={picsAutoExclusionUi}
            isRctClassifierEnabled={enableRctClassifier}
            isRctAutoExclusionEnabled={enableRctAutoExclusion}
            isPicsAutoExclusionEnabled={enablePicsAutoExclusion}
            onChange={({
              enableRctAutoExclusion,
              enableRctClassifier,
              enablePicsAutoExclusion,
            }) => {
              setEnableRctClassifier(enableRctClassifier);
              setEnableRctAutoExclusion(enableRctAutoExclusion);
              setEnablePicsAutoExclusion(enablePicsAutoExclusion);
            }}
          />
        </div>
      </div>

      <AccountControls
        submit={submit}
        errors={errors}
        isCochraneReview={!!isCochraneUser}
        accounts={accounts as BillableAccount[]}
        selectedAccountId={account.id}
        setSelectedAccountId={setSelectedAccountId}
        isSubmittable={isSubmittable}
        submitting={submitting}
      />
    </>
  );
};

export default NewCochraneLinkedReview;
