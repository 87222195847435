import React, { useRef, useEffect, useState, useImperativeHandle } from 'react';
import styles from '../Menu.module.scss';

interface MenuPosition {
  left: number | string;
  top: number | string;
  position: undefined | 'fixed';
  minWidth: number | string;
  visibility: undefined | 'visible';
}

interface MenuVisible {
  visibility: 'visible';
}

export type AlignmentOptions =
  | 'auto'
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top left'
  | 'top right'
  | 'bottom left'
  | 'bottom right';

interface Props {
  align?: AlignmentOptions;
  isLocked?: boolean;
  menuId: number;
  isMenuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
  className?: string;
  children: React.ReactNode;
  targetDisplay?: {
    ref: React.RefObject<HTMLElement>;
    setMinWidth?: boolean;
  };
}

export interface SimpleDomRect {
  top: number;
  left: number;
  bottom: number;
  width: number;
  height: number;
}

interface positionCalculatorProps {
  viewportWidth: number;
  viewportHeight: number;
  targetRect: SimpleDomRect;
  sourceRect: SimpleDomRect;
  setMinWidth: boolean | undefined;
  align: AlignmentOptions;
}
export const calculatePosition = ({
  viewportWidth,
  viewportHeight,
  targetRect,
  sourceRect,
  setMinWidth,
  align,
}: positionCalculatorProps) => {
  const newPosition: MenuPosition = {
    left: targetRect.left,
    top: targetRect.bottom,
    position: 'fixed',
    minWidth: 'inherit',
    visibility: 'visible',
  };

  if (setMinWidth) {
    newPosition.minWidth = targetRect.width;
  }

  if (
    align.match('right') ||
    (!align.match('left') && sourceRect.width + targetRect.left > viewportWidth)
  ) {
    newPosition.left = targetRect.left + targetRect.width - sourceRect.width;
  }
  if (
    align.match('top') ||
    (!align.match('bottom') &&
      sourceRect.height + targetRect.bottom > viewportHeight)
  ) {
    newPosition.top = targetRect.top - sourceRect.height;
  }

  return newPosition;
};

const MenuList = (
  {
    align = 'auto',
    isLocked = false,
    menuId,
    isMenuOpen,
    setMenuOpen,
    className,
    children,
    targetDisplay,
    ...props
  }: Props,
  ref: React.ForwardedRef<any>
) => {
  const classes = [styles.MenuList];
  const _ref = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => _ref.current);

  const [isRightAligned, setRightAligned] = useState(
    align.indexOf('right') >= 0
  );
  const [isAbove, setAbove] = useState(align.indexOf('top') >= 0);

  // Displaying the menu is a two step process.
  // A) set the class .open
  // B) set the visibility: visible;
  //
  // This allows for the browser to calculate the menu size
  // this is then used to calculate the position in the
  // MenuList component and the visibility is set once it is
  // positioned correctly. This prevents flickering on screen
  // when displaying a menu that needs to be realigned.

  const [position, setPosition] = useState<
    MenuPosition | undefined | MenuVisible
  >(undefined);

  if (isMenuOpen) {
    classes.push(styles.open);
  }

  if (className) classes.push(className);

  if (isRightAligned) classes.push(styles.right);
  if (isAbove) classes.push(styles.above);

  useEffect(() => {
    if (isMenuOpen) {
      if (!_ref.current) return;

      const rect = _ref.current.getBoundingClientRect();

      const viewportWidth =
        window.innerWidth || document.documentElement.clientWidth;
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;

      if (targetDisplay?.ref?.current) {
        // Positions menu to the supplied HTML Element in displayRef
        const targetRect = targetDisplay.ref.current.getBoundingClientRect();

        setPosition(
          calculatePosition({
            viewportWidth: viewportWidth,
            viewportHeight: viewportHeight,
            sourceRect: rect,
            targetRect: targetRect,
            setMinWidth: targetDisplay?.setMinWidth,
            align: align,
          })
        );
      } else {
        // using native browser positioning to parent element
        if (!align.match('right') && rect.left < 0) {
          setRightAligned(false);
        } else if (!align.match('left') && rect.right > viewportWidth) {
          setRightAligned(true);
        }
        if (!align.match('top') && rect.top < 0) {
          setAbove(false);
        } else if (!align.match('bottom') && rect.bottom > viewportHeight) {
          setAbove(true);
        }

        setPosition({ visibility: 'visible' });
      }
    } else {
      setPosition(undefined);
    }
  }, [isMenuOpen, align, targetDisplay, setPosition]);

  useEffect(() => {
    const elem = _ref.current;
    const clickableElems = elem?.querySelectorAll('a, button');

    const keyEvent = (e: KeyboardEvent) => {
      if (e.key === 'Escape') return setMenuOpen(isLocked);
    };

    // close the widget any time a clickable element is pressed, but not when just clicking the MenuList
    const closeEvent = () => {
      setMenuOpen(isLocked);
    };

    const focusOutEvent = (e: FocusEvent) => {
      const elemReceivingFocus = e.relatedTarget as Element;

      if (elem !== elemReceivingFocus && !elem?.contains(elemReceivingFocus))
        setMenuOpen(isLocked);
    };

    const windowEvent = (e: MouseEvent) => {
      if ((!isMenuOpen && !isLocked) || e.target === null) return;
      if (elem !== e.target && !elem?.contains(e.target as Element)) {
        setMenuOpen(isLocked);
      }
    };

    clickableElems?.forEach((el) =>
      (el as HTMLElement).addEventListener('click', closeEvent)
    );
    !isLocked && window.addEventListener('click', windowEvent);
    elem?.addEventListener('keydown', keyEvent);
    elem?.addEventListener('focusout', focusOutEvent);
    return () => {
      clickableElems?.forEach((el) =>
        (el as HTMLElement).removeEventListener('click', closeEvent)
      );
      window.removeEventListener('click', windowEvent);
      elem?.removeEventListener('keydown', keyEvent);
      elem?.removeEventListener('focusout', focusOutEvent);
    };
  }, [setMenuOpen, isMenuOpen, isLocked]);

  useEffect(() => {
    const closeMenuOnScroll = (event: Event) => {
      // discard tiny scroll events used by Capybara
      const targetElement = event.target as HTMLElement;
      if (targetElement.scrollTop < 2 && targetElement.scrollLeft < 2) return;

      const scrolledFromInsideMenu = _ref?.current?.contains(targetElement);

      // events originating within the menu list shouldn't close it
      if (!scrolledFromInsideMenu) setMenuOpen(false);
    };

    const closeMenuOnResize = () => {
      setMenuOpen(false);
    };

    let disconnectEvent = () => undefined;
    const target = targetDisplay?.ref?.current;

    if (target && isMenuOpen) {
      window.addEventListener('resize', closeMenuOnResize);
      window.addEventListener('scroll', closeMenuOnScroll, true);
      disconnectEvent = () => {
        window.removeEventListener('resize', closeMenuOnResize);
        window.removeEventListener('scroll', closeMenuOnScroll);
        return undefined;
      };
    }

    return disconnectEvent;
  }, [targetDisplay?.ref, isMenuOpen, setMenuOpen]);

  return (
    <div
      id={`covidence-menu-${menuId}`}
      role="menu"
      className={classes.join(' ')}
      ref={_ref}
      style={position}
      {...props}
    >
      {children}
    </div>
  );
};

const forwardedMenuList = React.forwardRef<
  any,
  Omit<Props, 'menuId' | 'isMenuOpen' | 'setMenuOpen'>
>(MenuList as any);
export default forwardedMenuList;
