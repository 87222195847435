export function normalize(str: string): string {
  return str.trim().toLowerCase();
}

function isRestrictedString(value: string, restricted: Array<string>) {
  const normalized = restricted.map((str) => normalize(str));
  return normalized.includes(normalize(value));
}

export const NO_EQUIVALENT_VALUE = null;

// Default error messages to use when the field is referred to as a 'Label'
export enum LabelError {
  RESTRICTED = 'Label matches template defaults. Please enter a unique label.',
  EQUIVALENT = 'Label is equivalent to "${otherValue}". Please enter a unique label.',
  UNIQUENESS = 'Label already used. Please enter a unique label.',
  REQUIRED = 'This field is required.',
  TOO_LONG = 'Label must be less than ${maxLength} characters.',
}

// Default error messages to use when the field is referred to as a 'Name'
export enum NameError {
  RESTRICTED = 'Name matches template defaults. Please enter a unique name.',
  EQUIVALENT = 'Name is equivalent to "${otherValue}". Please enter a unique name.',
  UNIQUENESS = 'Name already used. Please enter a unique name.',
  REQUIRED = 'This field is required.',
  TOO_LONG = 'Name must be less than ${maxLength} characters.',
}
export type ErrorMessages = {
  restrictedValueError: string;
  equivalentValueError: string;
  uniqueValueError: string;
  requiredValueError: string;
  maxLengthError: string;
};

const LabelErrorMessages: ErrorMessages = {
  restrictedValueError: LabelError.RESTRICTED,
  equivalentValueError: LabelError.EQUIVALENT,
  uniqueValueError: LabelError.UNIQUENESS,
  requiredValueError: LabelError.REQUIRED,
  maxLengthError: LabelError.TOO_LONG,
};

export const NameErrorMessages: ErrorMessages = {
  restrictedValueError: NameError.RESTRICTED,
  equivalentValueError: NameError.EQUIVALENT,
  uniqueValueError: NameError.UNIQUENESS,
  requiredValueError: NameError.REQUIRED,
  maxLengthError: NameError.TOO_LONG,
};

/**
 * Used to check if fields are unique
 * @param val string to check
 * @param existingValues existing values to check against, note for our react-hook-forms
 * we pass in the existing values which ALSO includes the new value we are checking against
 * @returns boolean
 */
export function isUnique(val: string, existingValues: Array<string>) {
  const normalizedVal = normalize(val);
  const duplicateCount = existingValues.reduce((count, s: string) => {
    if (normalizedVal === normalize(s)) return count + 1;
    return count;
  }, 0);

  return duplicateCount <= 1;
}

function valueExists(val: string, existingValues: Array<string>): boolean {
  const matchString = normalize(val);
  return (
    existingValues.find((existingValue: string) => {
      return normalize(existingValue) === matchString;
    }) !== undefined
  );
}

export const validate = (
  value: string,
  existingFields: Array<{ name: string }>,
  restrictedStrings: Array<string>,
  equivalentString: string | null,
  maxLength: number,
  errorMessages: ErrorMessages = LabelErrorMessages
): string | undefined => {
  const fieldNames = existingFields.map((field) => field.name);

  // treat values that are all whitespace like blank values.
  if (value.trim().length === 0) {
    return errorMessages.requiredValueError;
  }

  if (!isUnique(value, fieldNames)) {
    return errorMessages.uniqueValueError;
  }

  if (isRestrictedString(value, restrictedStrings)) {
    return errorMessages.restrictedValueError;
  }

  if (equivalentString !== null && valueExists(equivalentString, fieldNames)) {
    return errorMessages.equivalentValueError.replace(
      /\${otherValue}/,
      equivalentString
    );
  }

  if (maxLength && value.length > maxLength) {
    return errorMessages.maxLengthError.replace(
      /\${maxLength}/,
      maxLength.toString()
    );
  }
};
