import { getReviewId } from './Review';
import {
  AdditionalFieldCategory,
  AdditionalField,
  InterventionField,
  DataExtractionTemplate,
  OutcomeTypes,
  DataExtractionTemplateOutcome,
} from 'types/DataExtraction';
import {
  DATA_EXTRACTION_TEMPLATE_API_ROUTE,
  tokenizeRoute,
} from 'query/routes';
import { getCsrfToken } from 'query';

interface DataExtractionTemplateAPI {
  custom_fields: {
    id?: number;
    name: string;
    category: AdditionalFieldCategory;
  }[];
  interventions: {
    interventions: {
      id?: number;
      name: string;
    }[];
    characteristics: {
      id?: number;
      name: string;
    }[];
  };
  population: {
    baseline_characteristics: {
      id?: number;
      name: string;
    }[];
  };
  outcomes: {
    outcomes: DataExtractionTemplateOutcome[];
    default_continuous_measure: string;
    default_dichotomous_measure: string;
    data_types: {
      name: string;
      description: string;
      outcome_type: OutcomeTypes;
    }[];
  };
  has_extracted_data?: boolean;
  updated_by?: {
    first_name: string;
    last_name: string;
    id: string;
  };
  updated_at?: string;
}

interface DataExtractionTemplateAPIRequest extends DataExtractionTemplateAPI {
  revisit_studies: boolean;
}

interface DataExtractionTemplateAPIResponse extends DataExtractionTemplateAPI {
  number_of_revisitable_studies: number;
}

const getDETemplate = async (): Promise<DataExtractionTemplate> => {
  const reviewId = getReviewId();
  if (!reviewId) throw new Error("Couldn't get Review ID.");

  const url = tokenizeRoute(DATA_EXTRACTION_TEMPLATE_API_ROUTE, {
    review_id: reviewId,
  });

  return fetch(url.toString(), {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
    .then((res) => res.json())
    .then((res: DataExtractionTemplateAPIResponse) => {
      const {
        custom_fields = [],
        outcomes = { outcomes: [], data_types: [] },
        population = { baseline_characteristics: [] },
        interventions = { interventions: [], characteristics: [] },
        has_extracted_data = false,
        number_of_revisitable_studies,
        updated_by,
        updated_at,
      } = res;

      return {
        identification: {
          additionalFields: filterCustomFields(
            custom_fields,
            AdditionalFieldCategory.IDENTIFICATION
          ),
        },
        methods: {
          additionalFields: filterCustomFields(
            custom_fields,
            AdditionalFieldCategory.METHODS
          ),
        },
        population: {
          ...population,
          additionalFields: filterCustomFields(
            custom_fields,
            AdditionalFieldCategory.POPULATION
          ),
        },
        interventions: {
          interventions: mapInterventions(interventions.interventions),
          characteristics: mapInterventions(interventions.characteristics),
        },
        outcomes: {
          outcomes: outcomes.outcomes,
          default_continuous_measure: res.outcomes.default_continuous_measure,
          default_dichotomous_measure: res.outcomes.default_dichotomous_measure,
          data_types: outcomes.data_types,
        },
        has_extracted_data,
        number_of_revisitable_studies,
        updated_by,
        updated_at,
      };
    });
};

export type UpdateResponseBody = { errors?: Array<string> };

const updateDETemplate = async (
  {
    identification,
    methods,
    population,
    interventions,
    outcomes,
  }: DataExtractionTemplate,
  revisitStudies: boolean
): Promise<{
  status: number;
  body: UpdateResponseBody;
}> => {
  const allAdditionalFields = [
    ...asCustomField(methods.additionalFields, AdditionalFieldCategory.METHODS),
    ...asCustomField(
      identification.additionalFields,
      AdditionalFieldCategory.IDENTIFICATION
    ),
    ...asCustomField(
      population.additionalFields,
      AdditionalFieldCategory.POPULATION
    ),
  ];

  const withOutcomeReorder = outcomes.outcomes.map((outcome) => {
    const timepoints = outcome.timepoints.map((timepoint, index) => {
      return {
        ...timepoint,
        position: index + 1,
      };
    });
    return {
      ...outcome,
      timepoints,
    };
  });

  // Template updates should hardcode standard_deviation / event_count values
  const default_continuous_measure = 'standard_deviation';
  const default_dichotomous_measure = 'event_count';

  const body: DataExtractionTemplateAPIRequest = {
    custom_fields: allAdditionalFields,
    interventions: {
      interventions: interventions.interventions,
      characteristics: interventions.characteristics,
    },
    population: {
      baseline_characteristics: population.baseline_characteristics,
    },
    outcomes: {
      outcomes: withOutcomeReorder,
      default_continuous_measure,
      default_dichotomous_measure,
      // data types is set to an empty array to satisfy Typescript as we use the same
      // type for reading and writing the API, however the API does not use data type
      // on writes (it is not part of the write API and is ignored)
      data_types: [],
    },
    revisit_studies: revisitStudies,
  };

  const reviewId = getReviewId();
  if (!reviewId) throw new Error("Couldn't get Review ID.");

  const url = tokenizeRoute(DATA_EXTRACTION_TEMPLATE_API_ROUTE, {
    review_id: reviewId,
  });

  return fetch(url.toString(), {
    method: 'PUT',
    headers: {
      'X-CSRF-Token': getCsrfToken(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status === 200) {
      // All good, carry on
      return { status: response.status, body: {} as UpdateResponseBody };
    }
    return response.json().then((json) => {
      // parse the JSON body so we can do things with it
      return { status: response.status, body: json as UpdateResponseBody };
    });
  });
};

const createEmptyDETemplate = (): DataExtractionTemplate => {
  return {
    identification: {
      additionalFields: [],
    },
    methods: {
      additionalFields: [],
    },
    population: {
      baseline_characteristics: [],
      additionalFields: [],
    },
    interventions: {
      interventions: [],
      characteristics: [],
    },
    outcomes: {
      outcomes: [],
      data_types: [],
      default_continuous_measure: '',
      default_dichotomous_measure: '',
    },
    has_extracted_data: false,
    number_of_revisitable_studies: 0,
  };
};

const filterCustomFields = (
  fields: DataExtractionTemplateAPI['custom_fields'],
  category: AdditionalFieldCategory
): AdditionalField[] => {
  const idFields = fields.filter((field) => field.category === category);
  return idFields.map((field) => {
    return { id: field.id, name: field.name };
  });
};

const mapInterventions = (
  interventions: { id?: number; name: string }[]
): InterventionField[] => {
  return interventions.map((intervention) => {
    return {
      id: intervention.id,
      name: intervention.name,
    };
  });
};

const asCustomField = (
  fields: AdditionalField[],
  category: AdditionalFieldCategory
): DataExtractionTemplateAPI['custom_fields'] => {
  return fields.map((field) => {
    return { id: field.id, name: field.name, category: category };
  });
};

export { getDETemplate, updateDETemplate, createEmptyDETemplate };
