import React, { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import styles from '../DataExtractionTemplate.module.scss';
import { LabelError } from '../../components/editableFieldValidation';
import { Button, FormControl, Icon, IconList, Input } from 'components/core';

interface Props {
  name: string;
  index: number;
  onDelete: () => void;
  canDelete?: boolean;
  onBlur?: (event: any) => void;
  validate: (
    value: string,
    existingFields: Array<{ name: string }>
  ) => string | undefined;
}

const EditableField = ({
  name,
  index,
  onDelete,
  canDelete = true,
  validate,
  onBlur,
}: Props) => {
  const { getValues, trigger, getFieldState } = useFormContext();

  useWatch({ name });
  useEffect(() => {
    return () => {
      const { error, isDirty } = getFieldState(`${name}.${index}.name`);
      if (isDirty && error) {
        trigger(`${name}.${index}.name`).then(() => '');
      }
    };
  });

  return (
    <Controller
      rules={{
        required: LabelError.REQUIRED,
        onBlur,
        validate: (value: string) => {
          return validate(value, getValues(name));
        },
      }}
      name={`${name}.${index}.name` as const}
      render={({ field, fieldState }) => {
        return (
          <div className={styles.EditableField}>
            <FormControl error={fieldState.error?.message}>
              <Input
                {...field}
                variant="filled"
                data-testid={`${name}.${index}.name`}
              />
            </FormControl>
            {canDelete && (
              <Button
                data-testid={`${name}.${index}.delete`}
                iconOnly
                onClick={onDelete}
                variant="ghost"
              >
                <Icon icon={IconList.light.faTrashCan}>Delete</Icon>
              </Button>
            )}
          </div>
        );
      }}
    />
  );
};

export default EditableField;
