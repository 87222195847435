import React from 'react';
import styles from './AddOutcome.module.scss';
import { Icon, IconList, Link, Text } from 'components/core';

interface AddOutcomeProps {
  onClick: () => void;
  length: number;
}

const AddOutcome = ({ onClick, length }: AddOutcomeProps) => {
  const ShowLimitWarning = () => {
    if (length > 200) {
      return (
        <Text size="xs" variant="weak">
          You have {length} outcomes which is over the maximum limit of 200.
          Please delete outcomes to export your extracted data.
        </Text>
      );
    }
    if (length === 200) {
      return (
        <Text size="xs" variant="weak">
          You&apos;ve reached a maximum of 200 outcomes. Please delete outcomes
          if you need to add more.
        </Text>
      );
    }
    if (length >= 180) {
      return (
        <Text size="xs" variant="weak">
          You have {length} of max 200 outcomes
        </Text>
      );
    }

    return null;
  };

  const ShowOutcomeButton = () => {
    if (length >= 200) {
      return null;
    }

    return (
      <Link onClick={onClick} variant="primary">
        <Icon icon={IconList.light.faPlus} /> Add outcome
      </Link>
    );
  };

  return (
    <div className={styles.AddOutcome}>
      <ShowOutcomeButton />
      <ShowLimitWarning />
    </div>
  );
};

export default AddOutcome;
