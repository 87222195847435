import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './AutomationOptions.module.scss';
import { Checkbox, Text, Link, Badge } from 'components/core';
import { getReviewId } from 'query/review';

export type AutomationOptionsProps = {
  isRctClassifierEnabled: boolean;
  isRctAutoExclusionEnabled: boolean;
  isPicsAutoExclusionEnabled: boolean;
  onChange?: ({
    rctClassifierEnabled,
    rctAutoExclusionEnabled,
    picsAutoExclusionEnabled,
  }: Record<string, boolean>) => void;
  picsAutoExclusionUi: boolean;
};

export const AutomationOptions = ({
  isRctClassifierEnabled,
  isRctAutoExclusionEnabled,
  isPicsAutoExclusionEnabled,
  onChange,
  picsAutoExclusionUi,
}: AutomationOptionsProps): JSX.Element => {
  const reviewId = getReviewId();

  const [enableRctClassifier, setEnableRctClassifier] = useState(
    isRctClassifierEnabled
  );
  const [enableRctAutoExclusion, setEnableRctAutoExclusion] = useState(
    isRctAutoExclusionEnabled
  );
  const [enablePicsAutoExclusion, setEnablePicsAutoExclusion] = useState(
    isPicsAutoExclusionEnabled
  );

  const handleRctAutoExclusionChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.currentTarget.checked;
    setEnableRctAutoExclusion(isChecked);

    if (isChecked) {
      setEnableRctClassifier(true);
    }
  };

  const handleRctClassifierChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.currentTarget.checked;
    setEnableRctClassifier(isChecked);

    if (!isChecked) {
      setEnableRctAutoExclusion(false);
    }
  };

  const handlePicsAutoExclusionChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.currentTarget.checked;
    setEnablePicsAutoExclusion(isChecked);
  };

  useEffect(() => {
    onChange &&
      onChange({
        enableRctAutoExclusion,
        enableRctClassifier,
        enablePicsAutoExclusion,
      });
  }, [
    enableRctAutoExclusion,
    enableRctClassifier,
    enablePicsAutoExclusion,
    onChange,
  ]);

  return (
    <>
      {picsAutoExclusionUi && (
        <>
          <Text className={styles.checkboxDescription}>
            Use{' '}
            <Link
              external
              variant="primary"
              to=" https://support.covidence.org/help/pics-relevance-classifier"
            >
              PICS relevance classifier
            </Link>
            to:
          </Text>

          <Checkbox
            name="enable_pics_auto_exclusion"
            value="yes"
            checked={enablePicsAutoExclusion}
            onChange={handlePicsAutoExclusionChange}
          >
            <span className={styles.picsCheckboxLabel}>
              Remove ineligible references before screening based on your{' '}
              {reviewId === 'new' ? (
                'eligibility criteria'
              ) : (
                <Link variant="primary" to={`/reviews/${reviewId}/criteria`}>
                  eligibility criteria
                </Link>
              )}{' '}
              <Badge variant="solid" size="md">
                Early access
              </Badge>
            </span>
          </Checkbox>
        </>
      )}
      <Text className={styles.checkboxDescription}>
        Use{' '}
        <Link
          external
          variant="primary"
          to="https://support.covidence.org/help/automatically-tag-studies-not-reporting-on-rcts"
        >
          Cochrane RCT classifier
        </Link>
        to:
      </Text>

      <Checkbox
        name="enable_rct_classifier"
        value="yes"
        checked={enableRctClassifier}
        onChange={handleRctClassifierChange}
      >
        Tag references reporting on RCTs
      </Checkbox>

      <Checkbox
        name="enable_rct_auto_exclusion"
        value="yes"
        checked={enableRctAutoExclusion}
        onChange={handleRctAutoExclusionChange}
      >
        <span>
          Remove references reporting on non-RCTs before screening{' '}
          <Badge variant="solid" size="md">
            Early access
          </Badge>
        </span>
      </Checkbox>
    </>
  );
};

export const NewAutomationOptions = (props: AutomationOptionsProps) => {
  return (
    <div className={styles.newContainer}>
      <h3>Automation options</h3>

      <Text variant="weak" className={styles.description}>
        Use validated machine learning models to reduce your screening time by
        up to 45%. You can easily track the automated actions and turn them
        on/off at any point.
      </Text>
      <AutomationOptions {...props} />
    </div>
  );
};

export const EditAutomationOptions = (props: AutomationOptionsProps) => {
  return (
    <>
      <p className={styles.editHeading}>Automation options</p>
      <AutomationOptions {...props} />
    </>
  );
};
