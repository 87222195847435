import React, { useCallback, useContext, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { getOptionLabel } from 'concepts/Extraction/Blocks/shared/Option';
import { CompareDecision } from 'concepts/Extraction/Compare/CompareDecision';
import { ComparisonRowProps } from 'concepts/Extraction/Blocks/BlockRenderer';
import styles from 'concepts/Extraction/Compare/ComparisonTable/ComparisonTable.module.css';
import {
  BlockRecord,
  BlockRecords,
  BlockValue,
} from 'concepts/Extraction/BlocksData';
import { MultipleChoiceBlock } from 'concepts/Extraction/Blocks/MultipleChoice/MultipleChoiceBlock';
import { CompareButton } from 'concepts/Extraction/Compare/CompareButton';
import { ComparisonTableContext } from 'concepts/Extraction/Compare/ComparisonTable/ComparisonTable';
import { CompareNotificationBorder } from 'concepts/Extraction/Compare/CompareNotificationBorder';
import { useDebouncedFunction } from 'hooks/useDebouncedFunction';
export interface MultipleChoiceComparisonRowProps extends ComparisonRowProps {
  block: MultipleChoiceBlock;
}

const getCompareDecisionText = (
  record: BlockRecord<BlockValue>,
  block: MultipleChoiceBlock
): string => getOptionLabel(record, block).label;

const getCompareButtonText = (
  records: BlockRecords,
  block: MultipleChoiceBlock
): string => {
  const record = records?.[block.id];
  const { label, type } = getOptionLabel(record, block);
  return type === 'OtherOption' ? `Other: ${label}` : label;
};

export const MultipleChoiceComparisonRow = ({
  block,
  reviewerRecords,
  onResolveValue,
  readonly,
}: MultipleChoiceComparisonRowProps): JSX.Element => {
  const formContext = useFormContext();
  const { reviewerNames } = useContext(ComparisonTableContext);
  const [userResolved, setUserResolved] = useState<boolean>(false);

  const resolvedDecidedValue = useWatch({
    control: formContext.control,
    name: `resolvedData.${block.id}`,
  });

  const resolutionState = useWatch({
    control: formContext.control,
    name: `blockResolutions.${block.id}`,
  });

  const [consensusValue, setConsensusValue] = useState<string>(
    getCompareDecisionText(resolvedDecidedValue, block)
  );

  const debouncedResolve = useDebouncedFunction(
    (id, value) => onResolveValue(id, value),
    300
  );

  const handleResolveValue = useCallback(
    (value: string) => {
      // Check received value against both the option IDs and the labels,
      // because the consensus value can be set either by clicking one of the reviewer
      // data cells, in which case the ID of the option they chose is submitted,
      // OR the consensus value can be manually typed in, so we need to compare to the label.
      const chosenOption = block.options.find(
        (opt) => opt.id === value || opt.label === value
      );
      const consensusOptionLabel = chosenOption ? chosenOption.label : value;
      const consensusOptionValue = chosenOption ? chosenOption.id : value;
      setConsensusValue(consensusOptionLabel);
      debouncedResolve(block.id, consensusOptionValue);
    },
    [block, debouncedResolve]
  ) as (value: BlockValue) => void;

  const handleClick = useCallback(
    (value: string) => {
      handleResolveValue(value);
      setUserResolved(true);
    },
    [handleResolveValue]
  );

  return (
    <tr className={styles.row} data-block-id={block.id}>
      <th role="rowheader" className={styles.labelCell}>
        {block.label}
      </th>
      <td className={styles.decisionCell}>
        <CompareNotificationBorder
          notificationMessage="Check Decision"
          showNotification={
            !userResolved && resolutionState === 'supersededByExtractor'
          }
        >
          <CompareDecision
            resolutionState={resolutionState}
            readonly={readonly}
            label={block.label}
            value={consensusValue}
            onResolveValue={handleResolveValue}
          />
        </CompareNotificationBorder>
      </td>
      {reviewerRecords.map((reviewer, index) => {
        const buttonText = reviewer
          ? getCompareButtonText(reviewer, block)
          : '';

        const record = reviewer?.[block.id];
        const value = record?.value ?? '';
        const comment = record?.comment ?? '';
        const changed = !!record?.changed;

        return (
          <td className={styles.reviewerCell} key={index}>
            <CompareNotificationBorder
              notificationMessage="Data edited"
              showNotification={changed}
            >
              <CompareButton
                data-testid={`compare-button-${index}`}
                disabled={readonly}
                onClick={() => handleClick(value as string)}
                comment={
                  comment
                    ? {
                        comment,
                        commenter: reviewerNames[index],
                      }
                    : undefined
                }
              >
                {buttonText}
              </CompareButton>
            </CompareNotificationBorder>
          </td>
        );
      })}
    </tr>
  );
};
