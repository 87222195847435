import React, { useState, useEffect } from 'react';
import {
  ApolloError,
  OperationVariables,
  MutationResult,
  MutationFunctionOptions,
} from '@apollo/client';
import { ExecutionResult } from 'graphql';
import { Study } from './../StudyTypes';
import styles from './ManageReviewersModal.module.css';
import Dialog from 'system/base/Dialog';
import Select, { Option } from 'system/base/Select';
import { Radio } from 'system/elements';
import { PrimaryButton } from 'system/base/Button';
import { Link } from 'system/base/Link';
import { ReassignStudyReviewerMutation, User } from 'types/graphql';
import ErrorMessage from 'components/ErrorMessage';
import { LoadingSpinner } from 'components/core';

export interface ManageReviewersModalProps {
  isOpen: boolean;
  isLoading: boolean;
  fetchSelectableReviewersError: ApolloError | undefined;
  onClose: () => void;
  study: Study;
  selectableReviewers: Array<User> | [];
  reassignReviewersMutation: (
    options?:
      | MutationFunctionOptions<
          ReassignStudyReviewerMutation,
          OperationVariables
        >
      | undefined
  ) => Promise<ExecutionResult<ReassignStudyReviewerMutation>>;
  mutationResult: MutationResult<ReassignStudyReviewerMutation>;
}

export const ManageReviewersModal = ({
  isOpen,
  isLoading,
  fetchSelectableReviewersError,
  onClose,
  study,
  selectableReviewers,
  reassignReviewersMutation,
  mutationResult,
}: ManageReviewersModalProps): JSX.Element => {
  // Current extractors on the study
  const [currentExtractor1, setCurrentExtractor1] = useState<
    string | undefined
  >(undefined);
  const [currentExtractor2, setCurrentExtractor2] = useState<
    string | undefined
  >(undefined);
  const [currentConsensusReviewer, setCurrentConsensusReviewer] = useState<
    string | undefined
  >(undefined);

  // Actual drop down values
  const [selectedExtractor1, setSelectedExtractor1] = useState<
    string | undefined
  >(undefined);
  const [selectedExtractor2, setSelectedExtractor2] = useState<
    string | undefined
  >(undefined);
  const [selectedConsensusReviewer, setSelectedConsensusReviewer] = useState<
    string | undefined
  >(undefined);

  const [preserveData1, setPreserveData1] = useState(true);
  const [preserveData2, setPreserveData2] = useState(true);
  const [preserveDataConsensus, setPreserveDataConsensus] = useState(true);

  const [showMutationError, setShowMutationError] = useState(false);

  const { error, loading } = mutationResult;

  useEffect(() => {
    // If you close and reopen this modal after an error
    // on form submission, don't keep showing the error
    //
    setShowMutationError(false);
  }, []);

  useEffect(() => {
    const [extractor1, extractor2] = study.progress.extractionSlots.map(
      (slot) => slot.extractor
    );
    const consensusReviewer = study.progress.consensusSlot?.decider;

    if (extractor1 != undefined) {
      setCurrentExtractor1(extractor1.id);
      setSelectedExtractor1(extractor1.id);
    }
    if (extractor2 != undefined) {
      setCurrentExtractor2(extractor2.id);
      setSelectedExtractor2(extractor2.id);
    }
    if (consensusReviewer != undefined) {
      setCurrentConsensusReviewer(consensusReviewer?.id);
      setSelectedConsensusReviewer(consensusReviewer?.id);
    }
  }, [study]);

  const extractorReassignments = () => {
    const reassignments = [];

    if (
      currentExtractor1 !== undefined &&
      currentExtractor1 !== selectedExtractor1
    ) {
      reassignments.push({
        currentReviewerId: currentExtractor1,
        newReviewerId: selectedExtractor1,
        preserveData: preserveData1,
      });
    }

    if (
      currentExtractor2 !== undefined &&
      currentExtractor2 !== selectedExtractor2
    ) {
      reassignments.push({
        currentReviewerId: currentExtractor2,
        newReviewerId: selectedExtractor2,
        preserveData: preserveData2,
      });
    }

    return reassignments;
  };

  const consensusReassignment = () =>
    currentConsensusReviewer !== undefined &&
    selectedConsensusReviewer !== currentConsensusReviewer
      ? {
          reviewerId: selectedConsensusReviewer,
          preserveData: preserveDataConsensus,
        }
      : null;

  const selectableExtractionReviewers = (excludedOption: string | undefined) =>
    selectableReviewers
      .map((reviewer: User) => {
        return reviewer.id !== excludedOption ? (
          <Option key={reviewer.id} value={reviewer.id}>
            {reviewer.name}
          </Option>
        ) : null;
      })
      .filter((option) => option !== null);

  const reviewerSelection = (
    id: string,
    selectedOption: string | undefined,
    excludedOption: string | undefined,
    onChange: (value: string) => void
  ) => {
    const options = selectableExtractionReviewers(excludedOption);

    return (
      <Select
        data-testid={id}
        className={styles.extractionUserSelector}
        colors="primary"
        value={selectedOption}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
          onChange(event.target.value)
        }
      >
        {options}
      </Select>
    );
  };

  // Unlike selectableExtractionReviewers, this method doesn't
  // need to exclude already selected reviewers as an
  // extractor can also be the consensus decider for the same
  // study
  //
  const selectableConsensusReviewers = () =>
    selectableReviewers
      .map((reviewer: User) => (
        <Option key={reviewer.id} value={reviewer.id}>
          {reviewer.name}
        </Option>
      ))
      .filter((option) => option !== null);

  const showReassignmentOptions = (
    currentReviewer: string | undefined,
    selectedOption: string | undefined
  ) =>
    selectedOption !== '' &&
    selectedOption !== undefined &&
    selectedOption !== currentReviewer;

  const submitForm = (e: React.MouseEvent) => {
    e.preventDefault();

    reassignReviewersMutation({
      variables: {
        studyId: study.id,
        extractorReassignments: extractorReassignments(),
        consensusReassignment: consensusReassignment(),
      },
    })
      .then(() => {
        onClose();
        // else if there's an error, let component handle rendering it
      })
      .catch(() => {
        setShowMutationError(true);
      });
  };

  if (fetchSelectableReviewersError) {
    return (
      <Dialog
        isOpen={isOpen}
        onDismiss={onClose}
        title={'Reviewers for ' + study.title}
      >
        <p>Oops! Having trouble getting reviewers right now:</p>
        <ErrorMessage>
          <strong>{fetchSelectableReviewersError.name}:&nbsp;</strong>
          {fetchSelectableReviewersError.message}
        </ErrorMessage>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        isOpen={isOpen}
        onDismiss={onClose}
        title={'Reviewers for ' + study.title}
      >
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          // There should always be at least one reviewer if this modal is open,
          // StudyListItem doesn't render the button otherwise
          //
          <div data-testid="reviewer-management-dialog">
            <div className={styles.dropdownSection}>
              <h3>Reviewer 1</h3>
              {reviewerSelection(
                'reviewer-1-select',
                selectedExtractor1,
                selectedExtractor2,
                setSelectedExtractor1
              )}
            </div>
            {showReassignmentOptions(currentExtractor1, selectedExtractor1) ? (
              <div className={styles.reassignmentOptions}>
                <Radio
                  value="true"
                  onChange={() => setPreserveData1(true)}
                  checked={preserveData1}
                  label="Preserve extraction data"
                />
                <Radio
                  value="false"
                  onChange={() => setPreserveData1(false)}
                  checked={!preserveData1}
                  label="Clear extraction data"
                />
              </div>
            ) : null}
            <hr />

            {currentExtractor2 !== undefined ? (
              <>
                <div className={styles.dropdownSection}>
                  <h3>Reviewer 2</h3>
                  {reviewerSelection(
                    'reviewer-2-select',
                    selectedExtractor2,
                    selectedExtractor1,
                    setSelectedExtractor2
                  )}
                </div>
                {showReassignmentOptions(
                  currentExtractor2,
                  selectedExtractor2
                ) ? (
                  <div className={styles.reassignmentOptions}>
                    <Radio
                      value="true"
                      onChange={() => setPreserveData2(true)}
                      checked={preserveData2}
                      label="Preserve extraction data"
                    />
                    <Radio
                      value="false"
                      onChange={() => setPreserveData2(false)}
                      checked={!preserveData2}
                      label="Clear extraction data"
                    />
                  </div>
                ) : null}
                <hr />
              </>
            ) : null}

            {currentConsensusReviewer !== undefined ? (
              <>
                <div className={styles.dropdownSection}>
                  <h3>Consensus reviewer</h3>
                  <Select
                    className={styles.extractionUserSelector}
                    colors="primary"
                    value={selectedConsensusReviewer}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                      setSelectedConsensusReviewer(event.target.value)
                    }
                  >
                    {selectableConsensusReviewers()}
                  </Select>
                </div>
                {showReassignmentOptions(
                  currentConsensusReviewer,
                  selectedConsensusReviewer
                ) ? (
                  <div className={styles.reassignmentOptions}>
                    <Radio
                      value="true"
                      onChange={() => setPreserveDataConsensus(true)}
                      checked={preserveDataConsensus}
                      label="Preserve consensus data"
                    />
                    <Radio
                      value="false"
                      onChange={() => setPreserveDataConsensus(false)}
                      checked={!preserveDataConsensus}
                      label="Clear consensus data"
                    />
                  </div>
                ) : null}
                <hr />
              </>
            ) : null}

            <div className={styles.formButtonsContainer}>
              <Link href="#" onClick={onClose}>
                Cancel
              </Link>
              <PrimaryButton
                type="submit"
                onClick={submitForm}
                disabled={loading}
              >
                Save
              </PrimaryButton>
            </div>

            {showMutationError || error ? (
              <ErrorMessage>
                Couldn&apos;t update reviewers at this time. Please refresh the
                page and try again.
                {error !== undefined ? (
                  <>
                    <br />
                    <strong>{error.name}:&nbsp;</strong>
                    {error.message}
                  </>
                ) : null}
              </ErrorMessage>
            ) : null}
          </div>
        )}
      </Dialog>
    );
  }
};
