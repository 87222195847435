import React, { useEffect, useState } from 'react';
import styles from './EligibilityCriteria.module.scss';
import EligibilityCriteriaForm from './components/EligibilityCriteriaForm';
import {
  Banner,
  Button,
  Heading,
  Link,
  List,
  ListItem,
  Text,
} from 'components/core';
import { Criteria } from 'types';
import { getCriteria } from 'query/review/Criteria';
import { QueryProvider } from 'query/QueryProvider';
import { useReviewerFeatures } from 'hooks/reviewers/useReviewerFeatures';
import { useReviewFeatures } from 'hooks/reviews/useReviewFeatures';
import { Stack } from 'components/layout';

type EligibilityCriteriaProps = {
  renderExclusionReasonsModal: () => void;
  renderHighlightsModal: () => void;
};

const EligibilityCriteria = ({
  renderHighlightsModal,
  renderExclusionReasonsModal,
}: EligibilityCriteriaProps) => {
  const { hasFeature: hasReviewFeature } = useReviewFeatures();
  const { hasFeature: hasReviewerFeature } = useReviewerFeatures();
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [fetchingFailed, setFetchingFailed] = useState<boolean>(false);
  const [formData, setFormData] = useState<Criteria>();
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const isPICSEnabled = hasReviewFeature('pics_auto_exclusion') || false;
  const isPICSUIEnabled = hasReviewerFeature('pics_auto_exclusion_ui');

  useEffect(() => {
    getCriteria()
      .then((criteria) => {
        setFormData(criteria);
      })
      .catch(() => {
        setFetchingFailed(true);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, []);

  useEffect(() => {
    setShowSaveConfirmation(isPICSUIEnabled && isPICSEnabled);
  }, [isPICSUIEnabled, isPICSEnabled]);

  return (
    <Stack spacing={6} component="section" className={styles.container}>
      <header className={styles.header}>
        <Heading type="h3" className={styles.heading}>
          Eligibility criteria
        </Heading>

        <div className={styles.headerActions}>
          <Button
            type="neutral"
            variant="outline"
            onClick={renderHighlightsModal}
          >
            Highlights
          </Button>

          <Button
            type="neutral"
            variant="outline"
            onClick={renderExclusionReasonsModal}
          >
            Full-text exclusion reasons
          </Button>
        </div>
      </header>

      <div>
        <Text className={styles.introductionText}>
          Use the following features to help you screen and review studies:
        </Text>

        <List>
          <ListItem>
            Add{' '}
            <Link
              variant="primary"
              component="button"
              onClick={renderHighlightsModal}
            >
              highlights
            </Link>{' '}
            to quickly identify relevant studies, with keywords that are likely
            to indicate inclusion or exclusion.
          </ListItem>
          <ListItem>
            Customise{' '}
            <Link
              variant="primary"
              component="button"
              onClick={renderExclusionReasonsModal}
            >
              full-text exclusion reasons
            </Link>
            , and save time by listing these in order of importance and working
            down the list.
          </ListItem>
          <ListItem>
            Structure your review criteria using the{' '}
            <Link
              external
              variant="primary"
              to="https://support.covidence.org/help/creating-and-viewing-inclusion-criteria"
            >
              PICOS framework
            </Link>
            to visualise during title & abstract and full-text review.
          </ListItem>
        </List>
      </div>

      {!isFetching && fetchingFailed && (
        <Banner type="danger">
          <Text>Unable to load your criteria, please try again later.</Text>
        </Banner>
      )}

      {!isFetching && !fetchingFailed && (
        <EligibilityCriteriaForm
          initialData={formData}
          showSaveConfirmation={showSaveConfirmation}
        />
      )}
    </Stack>
  );
};

const EligibilityCriteriaPage = (props: EligibilityCriteriaProps) => (
  <QueryProvider>
    <EligibilityCriteria {...props} />
  </QueryProvider>
);

export default EligibilityCriteriaPage;
