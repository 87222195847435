import React from 'react';
import styles from './ConfirmationModal.module.scss';
import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'components/core';
import { Stack } from 'components/layout';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
};

const ConfirmationModal = ({ isOpen, handleClose, handleConfirm }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={handleClose} className={styles.wrapper}>
      <ModalHeader>Are you sure?</ModalHeader>
      <ModalBody>
        <Stack spacing={3}>
          <Text size="sm">
            You have enabled the <strong>PICS relevance classifier</strong>.
          </Text>
          <Text size="sm">
            After saving these eligibility criteria changes, the PICS relevance
            classifier may re-run across all references that have no manual
            votes. You can easily track the automated actions and turn them
            on/off at any point.
          </Text>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          type="neutral"
          variant="outline"
          size="sm"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button type="brand" size="sm" onClick={handleConfirm}>
          Yes, save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
