import React, { useContext, useEffect, useState } from 'react';
import ReviewLinkContext from '../../containers/Review/Home/ReviewLinkContext';

import { IconList, Link, Message, Text } from 'components/core';
import { useReviewFeatures } from 'hooks/reviews/useReviewFeatures';
import { getCriteria } from 'query/review/Criteria';

const IncompletePICSMessage = () => {
  // NOTE: using this context here as the reviewId provided by the parent
  //       is a GraphQL UUID, therefore not appropriate to shovel into
  //       a link
  const reviewLink = useContext(ReviewLinkContext);
  const { hasFeature } = useReviewFeatures();

  const [showMessage, setShowMessage] = useState(false);
  const [missingCategories, setMissingCategories] = useState<string[]>([]);

  const picsEnabled = hasFeature('pics_auto_exclusion');

  useEffect(() => {
    const requiredCategories = [
      'population',
      'intervention_exposure',
      'comparator_context',
      'study_characteristics',
    ];

    if (picsEnabled) {
      getCriteria()
        .then((criteria) => {
          let _missingCategories: string[] = requiredCategories;

          Object.keys(criteria).forEach((key) => {
            if (
              criteria[key]['include'].length !== 0 &&
              requiredCategories.includes(key)
            ) {
              _missingCategories = _missingCategories.filter((category) => {
                return category !== key;
              });
            }
          });

          if (_missingCategories.length !== 0) {
            setShowMessage(true);
            setMissingCategories(_missingCategories);
          }
        })
        .catch(() => {
          // Do nothing. This message is for a ReviewSummary page if the
          // user has enabled the PICS classifier but hasn't filled out
          // the requisite criteria. Thus, if the request does error
          // occasionally, just don't show this message.
        });
    }
  }, [picsEnabled]);

  const inclusionCriteriaLink = (text: string, anchor: string) => {
    return (
      <Link variant="primary" to={`${reviewLink}/criteria#${anchor}`}>
        {text}
      </Link>
    );
  };

  const inclusionCriteriaMessage = () => {
    const links = missingCategories.map((category) => {
      if (category === 'population') {
        return inclusionCriteriaLink('population', 'population');
      } else if (category === 'intervention_exposure') {
        return inclusionCriteriaLink('intervention', 'intervention_exposure');
      } else if (category === 'comparator_context') {
        return inclusionCriteriaLink('comparator', 'comparator_context');
      } else {
        // study_characteristics
        return inclusionCriteriaLink(
          'study characteristics',
          'study_characteristics'
        );
      }
    });

    return (
      <>
        Add{' '}
        {links.map((link, index) => (
          <React.Fragment key={index}>
            {link}
            {index === links.length - 2 && ' and '}
            {index < links.length - 2 && ', '}
          </React.Fragment>
        ))}{' '}
        inclusion criteria to enable the PICS relevance classifier
      </>
    );
  };

  if (!(picsEnabled && showMessage)) return null;

  return (
    <Message
      type="primary"
      variant="subtle"
      icon={IconList.solid.faSparkles}
      dismissible={false}
    >
      <Text>{inclusionCriteriaMessage()}</Text>
    </Message>
  );
};

export default IncompletePICSMessage;
