import React, { useRef, useImperativeHandle } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Props } from './Button.d';
import styles from './Button.module.scss';
import { Text, Icon, IconList, Loading } from 'components/core';
import { convertUrlToPath, useReactRouterLink } from 'components/core/router';

const Button = (
  {
    component,
    type = 'neutral',
    htmlType,
    variant = 'solid',
    size,
    iconOnly = false,
    onClick,
    to,
    external = false,
    className,
    isNaked,
    isLoading = false,
    children,
    disabled,
    ...props
  }: Props,
  ref: React.ForwardedRef<any>
) => {
  const shouldUseRouterLink = useReactRouterLink(to);
  const path = convertUrlToPath(to);

  let componentType = component;
  if (!componentType)
    componentType = to ? (shouldUseRouterLink ? RouterLink : 'a') : 'button';

  const classes = [styles.Button];
  if (!isNaked) classes.push(styles[type], styles[variant]);
  if (size) classes.push(styles[size]);
  if (iconOnly) classes.push(styles['iconOnly']);
  if (isLoading) classes.push(styles.loading);
  if (className) classes.push(className);

  const forwardedRef = useRef<any>(null);
  useImperativeHandle(ref, () => forwardedRef.current);

  // Raw text nodes breaks ellipsis with flexbox or some of the CSS breaks -- wrap raw text in <Text>
  const wrappedChildren = React.Children.map(children, (child) => {
    if (typeof child === 'string') {
      return (
        <Text component="span" className={styles.Button_text}>
          {child}
        </Text>
      );
    }
    return child;
  });

  return React.createElement<any>(
    componentType,
    {
      ref: forwardedRef,
      to: path,
      onClick,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      href: path,
      target: external ? '_blank' : undefined,
      rel: external ? 'noopener noreferrer' : undefined,
      type: htmlType,
      disabled: disabled || isLoading,
      className: classes.join(' ') || '',
      ...props,
    },
    wrappedChildren,
    external ? <Icon icon={IconList.solid.faExternalLink} /> : '',
    isLoading ? (
      <div className={styles.Button_loading}>
        <Loading />
      </div>
    ) : null
  );
};

const forwardedButton = React.forwardRef<any, Props>(Button) as React.FC<Props>;
export default forwardedButton;
export { forwardedButton as Button };
